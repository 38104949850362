<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        label-width="100px"
        label="条码类型："
        slot="codeType"
      >
        <el-select v-model="codeType">
          <el-option
            v-for="item in codeTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      initData: null,
      codeType: 'fx',
      formItemList: [
        { slotName: "codeType" },
        {
          key: "barCode",
          // isNumber: true,
          type: "input",
          labelName: "条码：",
          placeholder: "输入条码内容进行查询",
        },
      ],
      codeTypeOption: [
        {
          value: "fx",
          label: "繁星条码",
        },
        {
          value: "qt",
          label: "其他条码",
        },
      ],
    };
  },
  watch: {
    codeType(val){
      this.$emit("codeTypeChange", val);
    }
  },
  methods: {
    handleConfirm(data, cd) {
      console.log("提交了form", data);
      const SeachParams = {
        code: data.barCode,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      };
      this.$emit("barCodeQuery", SeachParams);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
