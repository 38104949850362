<template>
  <div class="class-list">
    <GlobalInfoBar
      title="二维码查询"
      content="说明："
    />
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <QrcodeSearchFrom @barCodeQuery="barCodeQuery" @codeTypeChange="codeTypeChange" />
      </div>

      <div class="search-result">
        <div class="search-title">
            <div class="title1">查询结果</div>
            <div class="title2" v-if="!tip.value">说明：{{codeType=='fx'?'请先输入二维码数字进行查询':'仅用于查询除繁星码以外的订单绑定条码'}}</div>
            <div v-else :class="tip.isSuccess?'success':'fail'">{{tip.value}}</div>
        </div>
        <div class="search-content" v-if="codeType=='fx'">
          <div>批 次 号：<span>{{dataForm.batchCode || '--'}}</span><span v-if="dataForm.batchCode" style="color:#0981ff;cursor: pointer;margin-left:20px" @click="seeDetails">查看详情</span></div>
          <div>生成时间：<span>{{dataForm.createTime || '--'}}</span></div>
          <div>是否激活：<span>{{activaType || '--'}}</span></div>
          <div>激活时间：<span>{{dataForm.activateTime || '--'}}</span></div>
          <div>所属门店商户：<span>{{dataForm.companyName || '--'}}</span></div>
          <div>使用时间：<span>{{dataForm.usedTime || '--'}}</span></div>
          <div>
            绑定原订单：
            <span v-if="dataForm.orderNo" @click="toOrder(dataForm.orderNo)" style="text-decoration: underline; cursor: pointer; color: #0981ff;">{{dataForm.orderNo}}</span>
            <span v-else>--</span>
            <span v-if="dataForm.merchantName" style="color: #F2A64C;margin-left: 20px;">{{ dataForm.merchantName }}</span>
          </div>
          <div>
            矩阵订单：
            <span v-if="dataForm.matrixOrderNo" @click="toOrder(dataForm.matrixOrderNo)" style="text-decoration: underline; cursor: pointer; color: #0981ff;">{{ dataForm.matrixOrderNo }}</span>
            <span v-else>--</span>
            <span v-if="dataForm.matrixMerchantName" style="color: #F2A64C;margin-left: 20px;">{{ dataForm.matrixMerchantName }}</span>
          </div>
        </div>
        <div class="search-content" v-if="codeType=='qt'" style="height:100px">
          <div>所属门店商户：<span>{{dataForm2.companyName?'三方—':''}}{{dataForm2.companyName || '--'}}</span></div>
          <div>绑定订单：<span v-if="dataForm2.orderNo" @click="toOrder(dataForm2.orderNo)" style="text-decoration: underline; cursor: pointer; color: #0981ff;">{{dataForm2.orderNo}}</span><span v-else>--</span></div>
        </div>
      </div>
    </GlobalChunk>
  </div>
</template>

<script>
import QrcodeSearchFrom from "./Form";
import _api from "@/utils/request";

export default {
  name: "class-list",
  components: { QrcodeSearchFrom },
  data() {
    return {
      dataForm:{},
      dataForm2:{},
      codeType: "fx",
      tip: {
        value:"",
        isSuccess:false,
      },
      activaType: ""
    };
  },
  methods: {
    // 条码类型改变
    codeTypeChange(val) {
      this.codeType = val
      this.tip.value = ""
      this.activaType = ""
    },
    barCodeQuery(params) {
      this.dataForm = {}
      this.dataForm2 = {}
      if(!params.code){
        this.tip.value = ""
        this.activaType = ""
        if (typeof params.disuseLoding === 'function') {
          params.disuseLoding();
        }
        return false
      }
      if(this.codeType == 'fx'){
        _api.barCodeQuery({barCode:params.code}).then(res => {
          if(res.code === 1){
            this.dataForm = res.data || {}
            let staffType = ""
            if(res.data){
              switch (res.data.staffType) {
                case '01':
                  staffType = '创建人'
                  break;
                case '02':
                  staffType = '总监'
                  break;
                case '03':
                  staffType = '店长'
                  break;
                case '04':
                  staffType = '店员'
                  break;
                default:
                  break;
              }
              if(res.data.activateType == '00' && res.data.activate=='01'){
                this.activaType = '已激活（后台激活）'
              }else if(res.data.activateType == '01' && res.data.activate=='01'){
                this.activaType = `已激活（门店激活${res.data.storeName?'--'+res.data.storeName:''}${res.data.staffName?'--'+res.data.staffName:''}${res.data.staffMobile?'--'+res.data.staffMobile:''}${staffType?'--'+staffType:''}）`
              }else{
                this.activaType = '未激活'
              }
              this.tip.value = '查询成功';
              this.tip.isSuccess = true;
            }else{
              this.tip.value = '未查询到该二维码，请检查号码是否正确'
              this.dataForm = {}
              this.activaType = ""
              this.tip.isSuccess = false;
            }
          }
          
        }).finally(() => {
          if (typeof params.disuseLoding === 'function') {
            params.disuseLoding();
          }
        })
      }else {
        _api.otherCodeQuery({code: params.code}).then(res => {
          if(res.code === 1){
            this.dataForm2 = res.data || {}
            if(res.data){
              this.tip.value = ""
            }else{
              this.tip.value = '未查询到该条码，请检查号码是否正确'
              this.dataForm2 = {}
              this.activaType = ""
              this.tip.isSuccess = false;
            }
          }
          if (typeof params.disuseLoding === 'function') {
            params.disuseLoding();
          }
        }).catch(()=>{
          this.tip.value = '未查询到该条码，请检查号码是否正确'
          this.dataForm2 = {}
          this.activaType = ""
          this.tip.isSuccess = false;
          if (typeof params.disuseLoding === 'function') {
            params.disuseLoding();
          }
        })
      }

    },
    seeDetails() {
      this.$router.push({
        path: "/qrcode/CreatDistribute",
        query: {batchCode: this.dataForm.batchCode },
      });
    },
    toOrder(orderNo) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: orderNo },
      });
      window.open(routeData.href, "_blank");
    }
  },
};
</script>

<style scoped lang="scss">
.search-result{
  margin-bottom: 80px;
  .search-title{
    display: flex;
    align-items: center;
    font-size: 14px;
    border-left: 8px solid #3399FF;
    padding: 0 10px;
    box-sizing: border-box;
    .title1{
        font-weight:bold;
        color:#333;
        margin-right: 10px;
    }
    .title2{
        color:#FFAF29;
        margin-right: 10px;
    }
  }
  .search-content{
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    padding-left: 20px;
    color: #333333;
    font-size: 14px;
    width: 500px;
    height: 250px;
    border-radius: 20px;
    margin-top: 20px;
    background-color: #EDEFFD;
  }
  .success{
    color: #80C269;
  }
  .fail{
    color: #f83232;
  }

}
</style>
